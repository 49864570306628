<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <h2>{{ route.name }}</h2>
        <div class="grid"> 
            <div v-for="item in children" :key="item.name" class="col-12">
                <Panel :header="item.name.toUpperCase()">
                    <ul>
                        <li v-for="child in item.children" :key="child.path">
                            <router-link :to="child.path">{{child.path}}</router-link>
                        </li>
                    </ul>
                </Panel>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        route() {
            return this.$route;
        },
        routes() {
            return this.$router.getRoutes();
        },
        children() {
            let tree = [];
            let children = this.$router
            .getRoutes()
            .filter(x => x.path.length >= this.$route.fullPath.length  && x.path != this.$route.fullPath)
            .filter(x => x.path.slice(0, this.$route.fullPath.length) == this.$route.fullPath)
            .map(x => {
                return {
                    path: x.path,
                    parent: x.path.split('/')[2]
                }
            });

            tree = [...new Set(children.map(item => item.parent))];
            let last = []
            tree.forEach(parent => {
                last.push({ name: parent, children: children.filter(x => x.parent == parent)})
            });
            return last;
        }
    }
}
</script>

<style>

</style>